import React from "react"

import { CustomHowWeDoIt } from "../components/how-we-do-it"
import Layout from "../components/layout"
import { PurpleHeroSection } from "../components/purple-hero-sections"
import SEO from "../components/seo"
import { OtherServices } from "./product-strategy"

const HeroSection = () => (
  <PurpleHeroSection
    titleFirst={"Enterprise"}
    titleSecond={"Solutions"}
    subtitle={"Streamlining your business processes"}
    content={
      "Creating one-of-a-kind software for corporate customers is a cornerstone of our expertise."
    }
    image={require("../assets/services-i-pm.svg")}
  />
)
const ERPSolutions = () => (
  <section className="">
    <div className="container px-5 md:px-12 pt-20 pb-32">
      <div className="pb-10 items-center grid grid-cols-1 md:grid-cols-12 gap-10">
        <div className="md:col-span-5 bg-pred text-white  shadow-lg mr-5 px-12 py-10 pb-24 ">
          <h4 className="font-black text-xl">
            Learn how Touchcore can build a custom ERP solution for your
            business
          </h4>
        </div>
        <div className="md:col-span-6 text-lg text-pgray">
          <p>
            When it comes to creating valuable ERP systems, Touchcore takes care
            of it all-drafting solution architecture, creating detailed
            requirements, implementation and deployment and maintenance and
            support.
          </p>
        </div>
      </div>
      <img
        alt={""}
        src={require("./../assets/nesa-by-makers-YgOCJz9uGMk-unsplash.png")}
      />
    </div>
  </section>
)
const HowWeDoIt = () => {
  const data = [
    {
      title: "Getting Started with Requirements",
      content:
        "We’ll start by learning about all of your business requirements, including current and future goals and challenges. This vital first step ensures that we know exactly what you want and need from design to deployment.",
    },
    {
      title: "Designing the Solution",
      content:
        "Building Touchcore ERP systems involves aligning a lot of moving parts, like integrating with existing platforms, migrating data, building infrastructure, and ensuring business continuity. That’s why we create a comprehensive design plan for each customer’s unique business needs.",
    },
    {
      title: "Developing the Solution",
      content:
        "Once the requirements and design plan are a go, Touchcore starts building the ERP system.",
    },
    {
      title: "Deploying the Solution",
      content:
        "After we’ve built the solution, Touchcore takes care of seamlessly integrating it with existing business processes, migrating data, and educating employees. Our support team is always available before, during, and after the solution is deployed.",
    },
  ]
  return <CustomHowWeDoIt data={data} />
}
const EnterpriseSolutions = () => (
  <Layout insightCategory="enterprise-solutions">
    <SEO
      title="Enterprise Solutions Development Company | Touchcore Technology Nigeria"
      description="Touchcore is a full-service software development company of engineers, designers, and developers. We have deep expertise and genuine skills in developing enterprise solutions of various complexity levels."
    />
    <HeroSection />
    <ERPSolutions />
    <HowWeDoIt />
    <OtherServices />
  </Layout>
)

export default EnterpriseSolutions
